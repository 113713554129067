<template>
  <Exploration
    :categoriesURL="apiURLs.EXPLORE.ORGANIZATIONS.CATEGORIES"
    :baseExploreURL="apiURLs.EXPLORE.ORGANIZATIONS.EXPLORE"
    :locationsURL="apiURLs.EXPLORE.ORGANIZATIONS.LOCATIONS"
    :filters="organizationFilters"
    :isOrganizationExplore="true"
  >
    <template #card="{ item, pageSettings }">
      <OrganizationCard img-top :organization="item" :pageSettings="pageSettings" class="card-dynamic h-100" />
    </template>
  </Exploration>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import Exploration from '@/components/explore/Exploration.vue'
import OrganizationCard from '@/components/explore/OrganizationCard.vue'
import { IOrganizationExploreFilter } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  components: {
    Exploration,
    OrganizationCard,
  },
  name: 'organization-explore',
})
export default class OrganizationExplore extends Vue {
  organizationFilters: IOrganizationExploreFilter = {
    searchString: '',
    categories: [],
    sortOrder: null,
    cmsFilter: null,
  }

  get apiURLs(): typeof API_URLS {
    return API_URLS
  }
}
</script>
