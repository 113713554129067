import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'

import ExploreDetail from '@/components/explore/ExploreDetail.vue'
import OrganizationExplore from '@/components/explore/OrganizationExplore.vue'

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/organization/:orgSlug',
      name: 'organization-detail',
      component: ExploreDetail,
      props: { isOrganization: true },
    },
    {
      path: '/organization/:orgSlug/voting/:votingSlug',
      name: 'organization-detail-voting',
      component: ExploreDetail,
      props: { isOrganization: true },
    },
    {
      path: '/:slug/',
      name: 'explore-organizations',
      component: OrganizationExplore,
    },
    {
      path: '/',
      name: 'explore-organizations',
      component: OrganizationExplore,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' }
  },
})

let initialLoad: { route: Route; pageTitle: string } = null

router.afterEach((to) => {
  delete to.query?.cashinModal // interferes with old JS code
  if (!initialLoad) {
    initialLoad = { route: to, pageTitle: document.title }
  }

  Vue.nextTick(() => {
    if (to.meta?.title) {
      document.title = to.meta.title
    } else if (initialLoad?.route.name === to.name) {
      document.title = initialLoad.pageTitle
    }
  })
})

export default router
