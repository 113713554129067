<template>
  <div class="card project-card">
    <b-img
      :src="organization.logo || '/static/img/default/project-placeholder-new.png'"
      onerror=""
      alt="Image"
      class="card-img-top pointer"
      @click="openOrg"
    />
    <div class="card-body py-1">
      <h4 class="card-title pointer" @click="openOrg">
        {{ organization.project_title || organization.title }}
      </h4>

      <p class="text-muted">
        {{ organization.title }}<br />
        {{ organization.city }}, {{ organization.country }}
      </p>
      <p v-if="!pageSettings.isVotingList">
        <span>
          <b class="collected-amount">{{ balance }} </b>
          <translate v-if="pageSettings.currencyDisplay.useCurrency" key="currency-collected">collected</translate>
          <translate v-else key="coins-collected"
            >{{ pageSettings.currencyDisplay.coinNamePlural }} collected</translate
          >
        </span>
        <br />
      </p>
      <div
        v-else-if="pageSettings.displayCurrentVotingRank"
        class="d-flex justify-content-between mb-3 rounded-lg p-2 mx-n2 bg-light"
      >
        <div>
          <span class="text-base-ci h5">{{ organization.votes }}</span>
          <br />
          <span key="vote-count" v-translate>Votes received</span>
        </div>
        <div class="text-right">
          <span class="text-base-ci h5">{{ organization.voting_rank }}</span>
          <br />
          <span key="voting-rank" v-translate>Current rank</span>
        </div>
      </div>
      <p class="mb-1" v-html="truncate(organization.project_summary, 70)" />
    </div>
    <b-card-footer>
      <b-button class="w-100" variant="base-ci" @click="openOrg">
        <translate v-if="!organization.is_status_archived"> View And Support Project </translate>
        <translate v-else>View Project</translate>
      </b-button>
    </b-card-footer>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IPageSettings } from '@/types/cms'
import { IExploreOrganizationMinimal } from '@/types/organizations'

@Component({
  name: 'organization-card',
})
export default class OrganizationCard extends Mixins(LocaleMixin) {
  @Prop() organization!: IExploreOrganizationMinimal
  @Prop() pageSettings: IPageSettings

  get balance(): string {
    const { balance } = this.organization

    return this.pageSettings.currencyDisplay.useCurrency
      ? this.toCurrency(balance, { currency: this.pageSettings.currencyDisplay.currencyIsoCode })
      : this.toCoins(balance, this.pageSettings.currencyDisplay.factorOfCurrencyToCoin)
  }

  truncate(title: string, length: number): string {
    if (title && title.length > length) {
      return `${title.slice(0, length - 3)} ...`
    }
    return title
  }

  openOrg() {
    // Filter out slug and preview_token from query params, as they are not needed in the project detail page and lead to 404
    const query = { ...this.$route.query, slug: undefined, preview_token: undefined }
    if (this.pageSettings.isVotingList && this.pageSettings.voting.slug) {
      this.$router.push({
        name: 'organization-detail-voting',
        params: { orgSlug: this.organization.slug, votingSlug: this.pageSettings.voting.slug },
        query: query,
      })
    } else {
      this.$router.push({
        name: 'organization-detail',
        params: { orgSlug: this.organization.slug },
        query: query,
      })
    }

    if (!this.pageSettings.singlePage) {
      location.reload()
    }
  }
}
</script>
